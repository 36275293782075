import type { CollectionSlide } from '@autobid/strapi-integration/typescript/strapi/collections/Slide'
import { useQuery, useQueryClient } from '@tanstack/vue-query'
import type { PublicationState } from '@autobid/ui/types/components/Slider'
import { IMAGE_FRAGMENT } from '@autobid/ui/constants/queries/IMAGE_FRAGMENT'
import { MATOMO_FRAGMENT } from '@autobid/ui/constants/queries/MATOMO_FRAGMENT'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import { useStrapiLang } from '@autobid/strapi-integration/composable/useStrapiLang'
import { SLIDES_SUPPORTED_LANGS } from '@autobid/strapi-integration/constants/SLIDES_SUPPORTED_LANGS'
import type { Theme } from '../types/Theme'
import { withOpacity } from '../utils/addOpacityToStrapiText'

function parseGroup(html: string, opacity: string | number) {
  const splitted = html
    .replace(/(<br\s*\/?>\s*){2,}/gi, 'split')
    .replaceAll('&nbsp;', '')
    .split('split')
  const grouped = splitted
    .map((html) => {
      const bgColorMatch = html.match(/background-color\s*:\s*([^;]+);/i)
      const backgroundColor = bgColorMatch
        ? withOpacity(bgColorMatch[1].trim(), opacity)
        : null // Extracts the color value

      const style = bgColorMatch ? `background-color: ${backgroundColor};` : ''
      return `<div class='px-4 py-2 [&_*]:p-0 [&_*]:m-0' style="${style}">${html.trim()}</div>`
    })
    .join('')

  return grouped
}

const flatSlidesData = (originalData: CollectionSlide[]) => {
  return originalData.map(
    ({ id, attributes: { slide, slideMobile, ...rest } }) => ({
      id,
      slide: {
        ...slide.data.attributes,
        id: slide.data.id
      },
      slideMobile: slideMobile?.data
        ? {
            ...slideMobile.data.attributes,
            id: slideMobile.data.id
          }
        : undefined,
      ...rest
    })
  )
}

export type FlattedSlideData = ReturnType<typeof flatSlidesData>

const getExpectedSlideData = (slides: FlattedSlideData) => {
  return slides.map((slide) => {
    if (!slide.backgroundOpacity) {
      return slide
    }
    const {
      title,
      description,
      auctionDescription,
      backgroundOpacity,
      ...rest
    } = slide

    return {
      title: parseGroup(title, slide.backgroundOpacity),
      description: description
        ? parseGroup(description, slide.backgroundOpacity)
        : undefined,
      auctionDescription: auctionDescription
        ? parseGroup(auctionDescription, slide.backgroundOpacity)
        : undefined,
      ...rest
    }
  })
}

export type CollectionName = 'slides'

const SLIDE_QUERY_DATA = `data {
        id
        attributes {
          title
          slide {
            ${IMAGE_FRAGMENT}
          }
          slideMobile {
            ${IMAGE_FRAGMENT}
          }
          description
          auctionDescription
          auctionDescriptionAlign
          locale
          backgroundOpacity
          url
          ${MATOMO_FRAGMENT}
        }
      }
    }`

export const getSlideQuery = (
  collectionName: CollectionName,
  pageCollectionName: string,
  slideLocale: string,
  userLocale: string,
  themeName: Theme
) => {
  const CURRENT_DATE = new Date().toISOString()

  const SLUGS_QUERY = `
    ${pageCollectionName}(locale: "${slideLocale}", pagination: {pageSize: 999}) {
      data {
        attributes {
          slug
          localizations(filters: {locale: {eq: "${userLocale}"}}) {
            data {
              attributes {
                slug
              }
            }
          }
        }
      }
    }
  `

  return `{
    ${collectionName}(
      locale: "${slideLocale}"
      sort: "rank:asc"
      filters: { 
      validFrom: { lte: "${CURRENT_DATE}" }
      or: [
        { validTo: { gte: "${CURRENT_DATE}" } }
        { validTo: null }
      ]
      apps: { contains: "${themeName}" }} ){
 ${SLIDE_QUERY_DATA}
 ${SLIDES_SUPPORTED_LANGS.includes(userLocale) ? '' : SLUGS_QUERY}
  }`
}

type GetSlideByIdQueryParams = {
  collectionName: CollectionName
  publicationState: PublicationState
  id: string
  locale: string
  themeName: Theme
}
export const getPreviewSlideQuery = ({
  collectionName,
  locale,
  id,
  publicationState,
  themeName
}: GetSlideByIdQueryParams) => {
  // publicationState is not wrapped with quotes because this is a ENUM not a string
  return `{
          ${collectionName}(
            locale: "${locale}"
            publicationState: ${publicationState}
            filters: { 
            id: {eq: "${id}"}
            apps: { containsi: "${themeName}" }} ){
       ${SLIDE_QUERY_DATA}
        }`
}

type FetchSlideParams = {
  query: string
  locale?: string
  preview?: boolean
}

type UseSlidesParams = {
  collectionName: string
  query: string
  locale?: string
  previewId?: string
}

export const useSlides = ({
  query,
  collectionName,
  locale,
  previewId
}: UseSlidesParams) => {
  const { strapiLang } = useStrapiLang()
  const lang = locale ?? strapiLang.value
  const queryClient = useQueryClient()
  const { $customFetch } = useCustomFetch()

  const fetchSlide = async ({ query, preview }: FetchSlideParams) => {
    const data = (await $customFetch(`/api/slides`, {
      query: {
        locale: lang,
        preview: preview ? '1' : '0',
        userLocale: strapiLang.value
      },
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: {
        queryApi: 'cms',
        query
      }
    })) as { data: { slides: { data: CollectionSlide[] } } }

    return data?.data.slides.data
  }

  const combinedFetch = async () => {
    const original = await fetchSlide({
      query,
      locale: lang,
      preview: Boolean(previewId)
    })
    const flatted = flatSlidesData(original)
    return getExpectedSlideData(flatted)
  }

  const prefetch = async () => {
    await queryClient.prefetchQuery({
      queryKey: [collectionName, lang, previewId],
      queryFn: combinedFetch
    })
  }

  return {
    ...useQuery([collectionName, lang, previewId], combinedFetch, {
      refetchOnWindowFocus: false
    }),
    prefetch
  }
}
